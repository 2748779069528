/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta, StateCta, TrustLogos, TwoColCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TrustLogos) _missingMdxReference("TrustLogos", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(LandingPageCta, {
    url: "https://www.hear.com/d/hearing_test/",
    copy: "Start hearing test",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-our-free-online-hearing-test-works",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-our-free-online-hearing-test-works",
    "aria-label": "how our free online hearing test works permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Our Free Online Hearing Test Works"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "getting-prepared",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-prepared",
    "aria-label": "getting prepared permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting Prepared"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Hear.com online hearing test is a tool that is designed to help individuals determine the quality of their hearing. It is a quick and convenient way to assess your hearing ability from the comfort of your own home, without the need to visit a healthcare professional."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To complete the test, you will need a pair of headphones and a device with an internet connection. The test typically takes less than five minutes to complete. At the end of the hearing test, you will receive a score out of ten."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "what-to-expect",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-to-expect",
    "aria-label": "what to expect permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What to Expect"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our hearing test page offers two distinct tests tailored to different user needs. The first test is designed for individuals who have access to headphones and involves playing a series of sounds. This allows us to evaluate their hearing ability based on their responses to various auditory stimuli. The second test caters to those who may not have headphones or prefer a different approach; it involves answering questions about day-to-day scenarios. Users rate the degree of difficulty they experience in these common hearing situations, providing a subjective measure of their hearing quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Upon completing either test, users receive a score out of ten that indicates the quality of their hearing. This score is accompanied by a personalized recommendation based on their performance, guiding them on potential next steps for their hearing health. Additionally, users are sent an email with their test results, ensuring they have a record of their hearing assessment and the recommendations provided. This dual-test approach ensures that we can offer a comprehensive and accessible hearing evaluation for all users."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "next-steps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#next-steps",
    "aria-label": "next steps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Next Steps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is important to remember that an online hearing test is not a substitute for a full hearing evaluation by a qualified audiologist. If you have any concerns about your hearing or are experiencing hearing problems, it is best to consult with a hearing healthcare professional. They can provide a more complete evaluation of your hearing and suggest appropriate treatment options if necessary."), "\n", React.createElement(LandingPageCta, {
    copy: "Find Out if You Qualify",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-fitting-hear.com.jpg",
    alt: "Behind the Ear Hearing Aids Fitting Process",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-of-hearing-loss",
    "aria-label": "signs of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs of Hearing Loss"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty hearing or understanding other people when they are speaking, especially in noisy environments."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Asking people to repeat themselves frequently."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turning up the volume on the television or radio louder than others find comfortable."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty hearing certain sounds, such as high-pitched noises or consonants."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing or buzzing in the ears (tinnitus)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Avoiding social situations because of difficulty hearing."), "\n"), "\n", React.createElement(LandingPageCta, {
    url: "https://www.hear.com/d/hearing_test/",
    copy: "Start hearing test",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "over-48-million-americans-have-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#over-48-million-americans-have-hearing-loss",
    "aria-label": "over 48 million americans have hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Over 48 million Americans have hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people don't realize they have hearing loss or what they're missing out on. With this 5-minute test, it's easy to check. Just listen to what other people have to say..."), "\n", React.createElement("div", {
    className: "js-trustpilot-widget",
    "data-locale": "en-US",
    "data-template-id": "54ad5defc6454f065c28af8b",
    "data-businessunit-id": "5601900f0000ff00058398fb",
    "data-style-height": "240px",
    "data-style-width": "100%",
    "data-theme": "light",
    "data-stars": "4,5",
    "data-review-languages": "en"
  }, React.createElement("a", {
    href: "https://www.trustpilot.com/review/hear.com",
    target: "_blank",
    rel: "noopener"
  }, "Trustpilot")), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " offer a ", React.createElement(_components.a, {
    href: "/about-us/hearing-success-program/",
    className: "c-md-a"
  }, "Customized Hearing Success Program"), ", the industry's first proven approach to hearing success. With over 92% customer satisfaction rate we're confident you'll be happy with our 45-day no-risk trial. Our 100% money-back guarantee means there's really no reason not to give our hearing aids a try."), "\n", React.createElement(TrustLogos, {
    type: "content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-recommendations",
    "aria-label": "our best recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the exclusive Horizon line of hearing aids by hear.com. No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-x-blue-blur.jpg",
    ContentTitle1: "Value & Price",
    ContentCopy1: "Are hearing aids worth the cost? We asked an expert.",
    Url1: "/hearing-aids/prices/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
